import React from "react";
import ArrowRightWhite from "../../../../../../assets/img/arrow-right-white.inline.svg"
import ArrowRightBlack from "../../../../../../assets/img/arrow-right-black.inline.svg"
import { Link } from "gatsby"
import { ImageTextLeftWithButtonStyle } from "../styles/InnerPagesStyle";


const ImageTextLeftWithButton = (props) => {

    return (
        <ImageTextLeftWithButtonStyle>
            <div className={`kuda-section`}>
                <div className="kuda-section--inner flex justify-between align-center">
                    <div className="kuda-section--55 kuda-text--right">
                        <div className="kuda-section--wrap  bottom--spacing">
                            <h1 className="kuda-section--heading text-xlbold color-black title-bottom--spacing">
                                {props.title}
                            </h1>
                            <p className="color-black kuda-section--max">
                                {props.subtitle}
                            </p>

                            <div className="kuda-section--max fit-content flex">
                                { (props.url && props.name) && 
                                    <>
                                        {typeof props.isExternal !== "undefined" ?
                                            <a
                                                target="_blank"
                                                href={props.url}
                                                className={`mt-4 dark-cta 
                                                ${props.name.includes('Business invoices') ? 'trans_button' : null}
                                                ${props.isWebButton ? "kuda_web" : "cta_button"}`}
                                                rel="noopener noreferrer"
                                            >
                                                <span className="mr-3">  {props.name}</span>
                                                {props.name.includes('Business invoices') ? <ArrowRightBlack /> : <ArrowRightWhite />}
                                            </a> :
                                            <Link
                                                to={props.url}
                                                className={`mt-4 dark-cta 
                                                ${props.name.includes('Business invoices') ? 'trans_button' : null}
                                                ${props.isWebButton ? "kuda_web" : "cta_button"}`}
                                            >
                                                <span className="mr-3">  {props.name}</span>
                                                {props.name.includes('Business invoices') ? <ArrowRightBlack /> : <ArrowRightWhite />}
                                            </Link>
                                        }
                                    </> 
                                }
                            </div>
                        </div>
                    </div>
                    <div className="kuda-section--45">
                        <div className="kuda-section--illustration">
                            {props.illustration}
                        </div>
                    </div>
                </div>
            </div>
        </ImageTextLeftWithButtonStyle>
    )
}

export default ImageTextLeftWithButton;
