import React from "react";
const DarkBelowFoldCards = (props) => {

    return (
        <div className={`kuda-below--fold color-black ${props.className ? props.className : ""}`} >
            {props.heading &&
                <h1 className="kuda-section--heading text-center text-xlbold color-black bottom--spacing--md">
                    {props.heading}
                </h1>
            }
            <div className="kuda-below--inner">
                <div className="kuda-summary--cards flex summary-top justify-between kuda-section--inner flex-wrap">
                    {props.topFeatures.map((item, i) =>
                        <div key={i} className="card card-item">
                            <div className="card-icon--spacing">
                                {item.icon}
                            </div>
                            <p className="text-xlbold">{item.text}</p>
                        </div>)
                    }
                </div>
                {typeof props.bottomFeatures !== "undefined" &&
                    <div className="kuda-summary--cards flex summary-bottom justify-center">
                        {props.bottomFeatures.map((item, i) =>
                            <div key={i} className="card card-item">
                                <div className="card-icon--spacing">
                                    {item.icon}
                                </div>
                                <p className="text-xlbold">{item.text}</p>
                            </div>)
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default DarkBelowFoldCards;
