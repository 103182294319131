import React from "react";
import ArrowRightWhite from "../../../../../../assets/img/arrow-right-white.inline.svg"
import ArrowRightBlack from "../../../../../../assets/img/arrow-right-black.inline.svg"

import { Link } from "gatsby"
import { ImageTextRightWithButtonStyle } from "../styles/InnerPagesStyle";

const ImageTextRightWithButton = (props) => {

    return (
        <ImageTextRightWithButtonStyle>
            <div className={`kuda-section`}>
                <div className="kuda-section--inner flex justify-between align-center mobile-reverse">
                    <div className="kuda-section--55">
                        <div className="kuda-section--illustration not-centered">
                            {props.illustration}
                        </div>
                    </div>
                    <div className="kuda-section--42 kuda-text--right">
                        <div className="kuda-section--wrap  bottom--spacing">
                            <h2 className="kuda-section--heading text-xlbold color-black title-bottom--spacing">
                                {props.title}
                            </h2>
                            <p className="color-black kuda-section--max">
                                {props.subtitle}
                            </p>

                            <div className="kuda-section--max fit-content flex">
                                { (props.url && props.name) &&
                                    <>
                                        {typeof props.isExternal !== "undefined" ?
                                            <a
                                                target="_blank"
                                                href={props.url}
                                                className={`mt-4 dark-cta ${props.name.includes('Buy') ? 'trans_button' : null} ${props.isWebButton ? "kuda_web" : "cta_button"}`}
                                                rel="noopener noreferrer"
                                            >
                                                <span className="mr-3">  {props.name}</span>
                                                {props.name.includes('Buy') ? <ArrowRightBlack /> : <ArrowRightWhite />}
                                            </a>
                                            :
                                            <Link
                                                to={props.url}
                                                className={`mt-4 dark-cta ${props.name.includes('Buy') ? 'trans_button' : null} ${props.isWebButton ? "kuda_web" : "cta_button"}`}
                                            >
                                                <span className="mr-3">  {props.name}</span>
                                                {props.name.includes('Buy') ? <ArrowRightBlack /> : <ArrowRightWhite />}
                                            </Link>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ImageTextRightWithButtonStyle>
    )
}

export default ImageTextRightWithButton;
